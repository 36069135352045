<template>
  <transition>
    <div class="m-dialog-mask" v-show="visible">
      <div
        :class="['m-dialog', center ? 'relative-hv-center' : 'top-center']"
        :style="`width: ${width}px; height: ${height}px; top: ${
          !center ? top + 'px' : '50%'
        }`"
      >
        <div class="m-dialog-content">
          <i class="el-icon-error u-close" @click="onClose"></i>
          <div
            class="m-dialog-body"
            :style="`height: calc(${height}px - 210px);`"
          >
            <slot>{{ content }}</slot>
          </div>
          <div class="m-dialog-footer">
            <el-button
              style="color: #000; font-weight: 600"
              type="primary"
              size="small"
              @click="onConfirm"
              >{{ okText }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "Dialog",
  props: {
    content: {
      // 内容 string | slot
      type: String,
      default: "",
    },
    width: {
      // 宽度，默认640
      type: Number,
      default: 640,
    },
    height: {
      // 高度，默认480
      type: Number,
      default: 480,
    },
    okText: {
      // 确认按钮文字
      type: String,
      default: "确定",
    },

    center: {
      // 水平垂直居中：true  固定高度水平居中：false
      type: Boolean,
      default: true,
    },
    top: {
      // 固定高度水平居中时，距顶部高度
      type: Number,
      default: 100,
    },
    visible: {
      // 对话框是否可见
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onConfirm() {
      this.$emit("ok");
    },
  },
};
</script>
<style lang="less" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
.flex-hv-center {
  // 水平垂直居中方法①：弹性布局，随内容增大高度，并自适应水平垂直居中
  display: flex;
  justify-content: center;
  align-items: center;
}
.relative-hv-center {
  // 水平垂直居中方法②：相对定位，随内容增大高度，并自适应水平垂直居中
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%); /* IE 9 */
  -webkit-transform: translateY(-50%); /* Safari and Chrome */
}
.top-center {
  // 相对定位，固定高度，始终距离视图顶端100px
  position: relative;
  // top: 100px;
}
.m-dialog-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.8); 
  .m-dialog {
    margin: 0 auto;
    transition: all 0.3s ease;
    .m-dialog-content {
      position: relative;
      background: #16191E; //背景色
      border-radius: 4px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      padding: 4px 28px;
      .u-close {
        font-size: 30px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        transition: fill 0.3s;
      }
      .m-dialog-header {
        height: 22px;
        padding: 16px 24px;
        color: rgba(0, 0, 0, 0.65);
        border-radius: 4px 4px 0 0;
        .u-head {
          margin: 0;
          color: rgba(0, 0, 0, 0.85);
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          word-wrap: break-word;
        }
      }
      .m-dialog-body {
        padding-top: 18px;
        font-size: 16px;
        line-height: 1.5;
        word-wrap: break-word;
        overflow: auto;
        transition: all 0.3s;
      }
      .m-dialog-footer {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
